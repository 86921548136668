import { css } from '@emotion/react'
// import React from 'react'
import { Component, memo } from 'react';
// import PropTypes from 'prop-types';
import loadable from "@loadable/component"
import {
  Container,
  Typography,
  Grid,
} from '@material-ui/core';
import namesCompany from '../../helpers/NameCompany'

const SimuladorCard = loadable(() => import('../pages/SimuladorCard'))
const CarouselCard = loadable(() => import('../carousel/CarouselCard'))

const simuladorCss = css`
    margin-top: 10px;
`
class Simulador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerless: true,
    }
  }

  async componentDidMount() {
    let urlParams = new URLSearchParams(window.location.search)
    let df;
    if (urlParams.has('headerless')) {
      df = urlParams.get('headerless')
      if (df === 'true') {
        this.setState({ headerless: true })
      } else {
        this.setState({ headerless: false })
      }
    }
  }

  render() {
    const { headerless } = this.state
    return (
      <Container>
        <Grid container spacing={2} alignItems="center">
          {
            !headerless && <Grid item xs={12} md={3}></Grid>
          }

          <Grid item xs={12} md={6}>
            <div>
              <Typography color="primary" variant="h6" align="center">
                {namesCompany(this.props.pageContext.company).slogan}
              </Typography>

              <div css={simuladorCss}>
                <SimuladorCard pageContext={this.props.pageContext} />
              </div>
              
              <br />
              
              <Typography variant="body2">
                <sup>*</sup>1 El presente simulador corresponde al crédito denominado Kfácildigital que Apoyo Económico Familiar, S.A. de C.V., SOFOM, E.N.R. (AEF) tiene registrado en el Registro de Contratos de Adhesión (RECA) de la CONDUSEF.
              </Typography>

              <Typography variant="body2">
                Los demás contratos de crédito que AEF tiene registrados en el RECA no cuentan con simulador.
              </Typography>
            </div>
          </Grid>

          {
            !headerless && <Grid item xs={12} md={3}></Grid>
          }

          {
            headerless &&
            <Grid item xs={12} md={6}>
              <CarouselCard company={this.props.pageContext.company} />
            </Grid>
          }
        </Grid>

      </Container>
    );
  }
}

export default memo(Simulador);